<template>
  <div>
    <Card class="mb-2 pb-2">
      <b-row class="mt-2">
        <b-col>
          <label>Canal</label>
          <b-form-select
            v-model="model.channel_id"
            :options="selectOptionsChannels"
            :disabled="editing"
          ></b-form-select>
        </b-col>
        <b-col v-if="model.channel_id !== ''">
          <label>Aplicação</label>
          <b-form-select
            v-model="model.application_id"
            :options="selectOptionsUnityApplications"
            :disabled="editing"
            @change="get_existing_models"
          ></b-form-select>
        </b-col>
        <b-col v-if="model.channel_id !== ''">
          <label>Tipo de Notificação</label>
          <b-form-select
            v-model="model.notification_type_id"
            :options="selectOptionsNotificationTypes"
            :disabled="editing"
            @change="get_existing_models"
          ></b-form-select>
        </b-col>
      </b-row>
    </Card>
    <template
      v-if="
        models.length > 0 && !showEditor && model.notification_type_id !== ''
      "
    >
      <b-alert show>
        <p class="d-flex align-items-center">
          Você já possui
          <b-badge pill variant="success mx-2">
            {{ models.length }}
          </b-badge>
          modelo desse tipo (listados abaixo), deseja criar um novo?
          <b-button class="ml-4" @click="showEditor = true" variant="info"
            >Continuar <fa-icon icon="arrow-right"></fa-icon
          ></b-button>
        </p>
        <hr />
        <p>
          Ou se preferir, clique em "Ver" para visualizar ou "Editar" para
          alterar um modelo.
        </p>
      </b-alert>

      <ModelsList :hide_active_field="'true'" v-if="!showEditor" />
    </template>
    <ChannelEmail
      v-if="
        model.channel_id == '1' &&
        model.notification_type_id !== '' &&
        (showEditor || models.length == 0)
      "
      :model="model"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      showEditor: false,
      model: {
        default: "0",
        application_id: null,
        unity_id: this.$store.state.auth.currentUser.unity_id,
        channel_id: "",
        notification_type_id: "",
        profile_id: null,
        description: "",
        design: "",
        html: "",
        header_id: "",
        footer_id: "",
        background_id: "",
        legacy: "",
        active: "",
      },
    };
  },
  components: {
    ChannelEmail: () => import("./ChannelEmail.vue"),
    ModelsList: () => import("./ModelsList.vue"),
  },
  computed: {
    state() {
      return this.$store.state.modelsCustom;
    },
    applicationsById() {
      return _.keyBy(this.state.applications, "id");
    },
    selectOptionsUnityApplications() {
      const firstOption = { text: "Todas", value: null };
      const normalized = _.map(this.state.unityApplications, (item) => {
        return {
          text: this.applicationsById[item.application_id].name,
          value: item.application_id,
        };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsNotificationTypes() {
      const firstOption = { text: "Selecione", value: "", disabled: true };
      const filtered = _.filter(this.state.notificationTypes, (item) => {
        return item.channel_id == this.model.channel_id;
      });
      const normalized = _.map(filtered, (item) => {
        return {
          text: item.description,
          value: item.id,
        };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsChannels() {
      return _.map(this.state.channels, (channel) => {
        return {
          text: channel.channel,
          value: channel.id,
          disabled: channel.active == "1" ? false : true,
        };
      });
    },
    models() {
      return this.state.models;
    },
  },
  methods: {
    async get_existing_models() {
      this.showEditor = false;
      this.$store.dispatch("modelsCustom/get_models", {
        notification_type_id: this.model.notification_type_id,
        application_id: this.model.application_id,
        default: "0",
        legacy: "0",
      });
    },
  },
  async mounted() {
    const templateId = this.$route.params.templateId;
    if (templateId) {
      const model = await this.$store.dispatch("modelsCustom/get_models", {
        id: templateId,
      });
      this.model = model[0];
      this.editing = true;
      console.log(this.model);
    }

    this.$store.dispatch("modelsCustom/add_edit_init");
  },
};
</script>

<style>
</style>