var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{staticClass:"mb-2 pb-2"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('label',[_vm._v("Canal")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsChannels,"disabled":_vm.editing},model:{value:(_vm.model.channel_id),callback:function ($$v) {_vm.$set(_vm.model, "channel_id", $$v)},expression:"model.channel_id"}})],1),(_vm.model.channel_id !== '')?_c('b-col',[_c('label',[_vm._v("Aplicação")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsUnityApplications,"disabled":_vm.editing},on:{"change":_vm.get_existing_models},model:{value:(_vm.model.application_id),callback:function ($$v) {_vm.$set(_vm.model, "application_id", $$v)},expression:"model.application_id"}})],1):_vm._e(),(_vm.model.channel_id !== '')?_c('b-col',[_c('label',[_vm._v("Tipo de Notificação")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsNotificationTypes,"disabled":_vm.editing},on:{"change":_vm.get_existing_models},model:{value:(_vm.model.notification_type_id),callback:function ($$v) {_vm.$set(_vm.model, "notification_type_id", $$v)},expression:"model.notification_type_id"}})],1):_vm._e()],1)],1),(
      _vm.models.length > 0 && !_vm.showEditor && _vm.model.notification_type_id !== ''
    )?[_c('b-alert',{attrs:{"show":""}},[_c('p',{staticClass:"d-flex align-items-center"},[_vm._v(" Você já possui "),_c('b-badge',{attrs:{"pill":"","variant":"success mx-2"}},[_vm._v(" "+_vm._s(_vm.models.length)+" ")]),_vm._v(" modelo desse tipo (listados abaixo), deseja criar um novo? "),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"info"},on:{"click":function($event){_vm.showEditor = true}}},[_vm._v("Continuar "),_c('fa-icon',{attrs:{"icon":"arrow-right"}})],1)],1),_c('hr'),_c('p',[_vm._v(" Ou se preferir, clique em \"Ver\" para visualizar ou \"Editar\" para alterar um modelo. ")])]),(!_vm.showEditor)?_c('ModelsList',{attrs:{"hide_active_field":'true'}}):_vm._e()]:_vm._e(),(
      _vm.model.channel_id == '1' &&
      _vm.model.notification_type_id !== '' &&
      (_vm.showEditor || _vm.models.length == 0)
    )?_c('ChannelEmail',{attrs:{"model":_vm.model}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }